var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Search & Filter ")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search: User Account (email@, phone>6digit)","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}})],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.quotaGroupListTable,"options":_vm.options,"server-items-length":_vm.totalListTable,"loading":_vm.loading,"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.groupable_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-wrap":"pretty","min-width":"300px"}},[_vm._v(" "+_vm._s(_vm.t(item.groupable.title))+" ")])]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.user.email)+", "+_vm._s(item.user.phone))])]}},{key:"item.participant_id",fn:function(ref){
var item = ref.item;
return [(item.participant)?_c('div',{staticStyle:{"min-width":"150px"}},[_c('div',[_vm._v(_vm._s(item.participant.first_name)+" "+_vm._s(item.participant.last_name))]),_c('div',[_vm._v(_vm._s(item.participant.email)+", "+_vm._s(item.participant.phone))])]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"140px"}},[_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.resendQuotaGroup(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEmail)+" ")]),_c('span',[_vm._v("Resend Welcome Email: quota_group.created")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.resendQuotaTopup(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEmail)+" ")]),_c('span',[_vm._v("Resend Purchase Email: quota_topup.created")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.forceExpireQuotaGroup(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Force Expire")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }