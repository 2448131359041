import { fetchQuotaGroups, forceExpireQuotaGroup, resendQuotaGroup, resendQuotaTopup } from '@/api/user/quota'
import { useConfirm, useNotifyErrors, useTableOptions } from '@/composables'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useQuotaGroup(query = {}) {
  const quotaGroupListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'PRODUCT', value: 'groupable_id' },
    { text: 'USER', value: 'user_id' },
    { text: 'PARTICIPANT', value: 'participant_id' },
    { text: 'STATUS', value: 'status' },
    { text: 'SINCE', value: 'created_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  const quotaGroupOptions = computed(() =>
    quotaGroupListTable.value.map(og => ({
      title: og.name.en,
      value: og.id,
    })),
  )

  // fetch data
  const loadQuotaGroups = () =>
    fetchQuotaGroups(
      useTableOptions(options.value, {
        search: searchQuery.value,
        ...query,

        // root_only: 1,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        quotaGroupListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false

        return records
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadQuotaGroups()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    quotaGroupListTable,
    tableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadQuotaGroups,
    quotaGroupOptions,
    forceExpireQuotaGroup: async group => {
      await useConfirm({
        title: 'Confirm FORCE EXPIRE this quota?',
        content: 'You may force expire q quota if it does not have unlimited quota. This action cannot be reversed.',
      })
      await forceExpireQuotaGroup(group.id).catch(useNotifyErrors)
      await loadQuotaGroups()
    },
    resendQuotaGroup: async group => {
      await useConfirm({
        title: 'Confirm Resend "quota_group.created"?',
        content: 'Resend a welcome email to user and participant. ProductTemplate "quota_group.created" must be defined.',
      })
      await resendQuotaGroup(group.id).catch(useNotifyErrors)
      await loadQuotaGroups()
    },
    resendQuotaTopup: async group => {
      await useConfirm({
        title: 'Confirm Resend "quota_topup.created"?',
        content: 'Resend a welcome email to user and participant. ProductTemplate "quota_topup.created" must be defined.',
      })
      await resendQuotaTopup(group.id).catch(useNotifyErrors)
      await loadQuotaGroups()
    },
  }
}
