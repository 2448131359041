<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="6"
          class="d-flex"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search: User Account (email@, phone>6digit)"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="quotaGroupListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <template #[`item.id`]="{item}">
          {{ item.id }}
        </template>

        <template #[`item.groupable_id`]="{item}">
          <div style="text-wrap: pretty; min-width: 300px;">
            {{ t(item.groupable.title) }}
          </div>
        </template>

        <template #[`item.user_id`]="{item}">
          <div>{{ item.user.email }}, {{ item.user.phone }}</div>
        </template>

        <template #[`item.participant_id`]="{item}">
          <div
            v-if="item.participant"
            style="min-width: 150px;"
          >
            <div>{{ item.participant.first_name }} {{ item.participant.last_name }}</div>
            <div>{{ item.participant.email }}, {{ item.participant.phone }}</div>
          </div>
        </template>

        <template #[`item.created_at`]="{item}">
          <div style="min-width: 140px;">
            {{ item.created_at | date }}
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="resendQuotaGroup(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEmail }}
                  </v-icon>
                  <span>Resend Welcome Email: quota_group.created</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="resendQuotaTopup(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEmail }}
                  </v-icon>
                  <span>Resend Purchase Email: quota_topup.created</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="forceExpireQuotaGroup(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Force Expire</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
mdiDeleteOutline,
mdiDotsVertical,
mdiEmail,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { t } from '@/plugins/i18n'
import { avatarText, date } from '@core/utils/filter'
import useQuotaGroup from './useQuotaGroup'

export default {
  filters: { date },
  setup() {
    const {
      quotaGroupListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadQuotaGroups,
      forceExpireQuotaGroup,
      resendQuotaGroup,
      resendQuotaTopup,
    } = useQuotaGroup()

    const isAddNewQuotaGroupSidebarActive = ref(false)
    const resource = ref(false)

    return {
      quotaGroupListTable,
      tableColumns,
      searchQuery,
      totalListTable,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      isAddNewQuotaGroupSidebarActive,
      forceExpireQuotaGroup,
      resendQuotaGroup,
      resendQuotaTopup,
      resource,
      selectedRows,

      avatarText,
      loadQuotaGroups,

      t,

      // icons
      icons: {
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiEmail,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
