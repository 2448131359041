import axios from '@axios'

const fetchQuotaGroups = params => axios.get('/admin/user-quota-groups', { params })

const forceExpireQuotaGroup = id => axios.post(`/admin/user-quota-groups/${id}/force-expire`)

const resendQuotaGroup = id => axios.post(`/admin/user-quota-groups/${id}/resend`)

const resendQuotaTopup = id => axios.post(`/admin/user-quota-groups/${id}/resend-topup`)

export { fetchQuotaGroups, forceExpireQuotaGroup, resendQuotaGroup, resendQuotaTopup }
